<template>
  <div>
    <v-sheet
      height="51px"
      width="100%"
      color="#EDE5EB"
      rounded="lg"
      class="d-flex align-center rounded-t-0 white--text text-subtitle-1">
      <v-row
        class="d-flex align-center">
        <v-col
          :class="colClass">
          <span
            :class="titleClass">
            {{ sumLives }}
          </span>
        </v-col>
        <v-col
          :class="colClass">
          <span
            :class="titleClass">
            {{ sumPremium }}
          </span>
        </v-col>
        <v-col
          :class="colClass">
          <span
            :class="titleClass">
            {{ sumIof }}
          </span>
        </v-col>
        <v-col
          :class="colClass">
          <span
            :class="titleClass">
            {{ sumOutherValues }}
          </span>
          <v-btn
            class="ml-2"
            icon
            elevation="4"
            style="background-color: white;"
            @click="openReviewModal">
            <v-icon
              color="wine">
              fas fa-eye
            </v-icon>
          </v-btn>
        </v-col>
        <v-col
          :class="colClass">
          <span
            :class="titleClass">
            {{ sumRetroativity }}
          </span>
        </v-col>
        <v-col
          :class="colClass">
          <span
            :class="titleClass">
            {{ sumTotalPremium }}
          </span>
        </v-col>
      </v-row>
    </v-sheet>
    <InvoiceReviewModal ref="InvoiceReviewModal" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import InvoiceReviewModal from './InvoiceReviewModal.vue';
import Formatters from '@/shared/formatters/formatters';

export default {
  name: "PreviewTotal",

  components: {
    InvoiceReviewModal
  },

  components: {
    InvoiceReviewModal
  },

  data: () => ({
    titleClass: 'text-subtitle-1 wineLabel--text',
    colClass: 'd-flex justify-center align-center',
    formatter: null,
  }),

  methods: {
    openReviewModal() {
      this.$refs.InvoiceReviewModal.open(this.invoicePreview);
    }
  },

  computed: {
    ...mapGetters({
      selectedPreviews: 'operational-routines/selectedPreviews'
    }),
    invoicePreview() {
      return {
        totalLives: this.sumLives,
        premium: this.sumPremium,
        Iof: this.sumIof,
        outherValues: this.sumOutherValues,
        retroativity: this.sumRetroativity,
        totalPremium: this.sumTotalPremium,
        previewValues: this.sumPreviewValues,
      }
    },
    sumLives() {
      return this.selectedPreviews.reduce((total, el) => {
        return total + el.totalLives;
      }, 0)
    },
    sumTotalPremium() {
      const total = this.selectedPreviews.reduce((total, el) => {
        return total + parseFloat(el.invoiceValue);
      }, 0)

      return this.formatter.formatCurrency(total);
    },
    sumIof() {
      const total = this.selectedPreviews.reduce((total, el) => {
        const IOF = el.previewValues.IOF;

        if (IOF !== undefined && IOF !== null && IOF !== '') {
          return total + parseFloat(IOF);
        }
        return total;
      }, 0);

      return this.formatter.formatCurrency(total);
    },
    sumOutherValues() {
      const total = this.selectedPreviews.reduce((total, el) => {
        return total + parseFloat(this.formatter.calculeOutherValue(el.previewValues));
      }, 0)

      return this.formatter.formatCurrency(total);
    },
    sumRetroativity() {
      const total = this.selectedPreviews.reduce((total, el) => {
        return total + parseFloat(this.formatter.calculeRetroactivity(el.previewValues));
      }, 0)

      return this.formatter.formatCurrency(total);
    },
    sumPremium() {
      const total = this.selectedPreviews.reduce((total, el) => {
        return total + parseFloat(this.formatter.calculePremium(el.previewValues));
      }, 0)

      return this.formatter.formatCurrency(total);
    },
    sumPreviewValues() {
      const total = this.selectedPreviews.reduce((total, obj) => {
        for (const key in obj.previewValues) {
          if (total[key]) {
            total[key] += obj.previewValues[key];
          } else {
            total[key] = obj.previewValues[key];
          }
        }
        return total;
      }, {});

      return total;
    },
  },

  created() {
    this.formatter = new Formatters();
  }
}
</script>
