<template>
  <div
    class="fill-height white">
    <v-container
      fluid
      class="px-5">
      <v-row>
        <v-col
          cols="12"
          class="pb-0">
          <span
            class="text-subtitle-1 wineTitle--text">
            PAINEL DE CONTROLE DAS ROTINAS OPERACIONAIS
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12">
          <v-tabs
            id="OperationalRoutinesTabs"
            v-model="pageTab"
            background-color="transparent">
            <v-tabs-slider color="wine" style="height: 5px;" />
            <v-tab
              key='conference'
              href='#conference'
              :class="[
                pageTabClass,
                {
                  'wineTitle--text': pageTab !== 'conference',
                  'wineLabel--text':  pageTab === 'conference'
                }
              ]">
              Arquivos de Fatura
            </v-tab>
            <v-tab
              key='validations'
              href='#validations'
              :class="[
                pageTabClass,
                {
                  'wineTitle--text': pageTab !== 'validations',
                  'wineLabel--text':  pageTab === 'validations'
                }
              ]">
              Validações
            </v-tab>
            <v-tab
              key='details'
              href='#details'
              :class="[
                pageTabClass,
                {
                  'wineTitle--text': !['details', 'selected'].includes(pageTab),
                  'wineLabel--text': ['details', 'selected'].includes(pageTab)
                }
              ]"
              @click="notifyUser"
              style="display: none;">
              Detalhes
            </v-tab>
            <v-tab
              key='selected'
              href='#selected'
              style="display: none;">
            </v-tab>
            <v-tab
              key='previews'
              href='#previews'
              :class="[
                pageTabClass,
                {
                  'wineTitle--text': pageTab !== 'previews',
                  'wineLabel--text':  pageTab === 'previews'
                }
              ]">
              Prévia de Faturas
            </v-tab>
          </v-tabs>
          <v-tabs-items
            v-model="pageTab"
            class="transparent">
            <v-tab-item
              key='conference'
              value='conference'>
              <v-row
                class="my-1">
                <v-col
                  cols="12">
                  <v-form
                    ref="ConferenceForm">
                    <ConferenceFilter
                      ref="ConferenceFilter"
                      :financialGroups="financialGroups"
                      :insuranceCarriers="insuranceCarriers"
                      :contracts="contracts"
                      :subcontracts="subcontracts"
                      :resetFilterConference="resetFilterConference"
                      :filtersForCheckDate="filtersForCheckDate"
                      :loadSearch="loadSearch"
                      @handlerClickFilter="handlerClickFilter"
                      @resetStatusFilters="resetStatusFilters"
                      @hasFiltersConference="hasFiltersConference"
                      @clearFilters="clearFilters"
                      @openExpandedContent="openExpandedContent"
                    />
                  </v-form>
                </v-col>
                <v-col
                  cols="12"
                  class="pt-0">
                  <ConferenceTable
                    ref="ConferenceTable"
                    :financialGroups="financialGroups"
                    :insuranceCarriers="insuranceCarriers"
                    :contracts="contracts"
                    :subcontracts="subcontracts"
                    @loadSearch="loadSearch = $event"
                  />
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item
              key='validations'
              value='validations'>
              <v-row
                class="mb-1">
                <v-col
                  cols=12>
                  <v-form
                    ref="SimpleFilter">
                    <SimpleFilter
                      @search="handlerFilter"
                      :loadingSearch="loadingSearch"
                    />
                  </v-form>
                </v-col>
                <v-col
                  cols="12">
                  <InvoiceConference
                    v-if="invoiceValidations.length > 0"
                    @selectCard="selectCard"
                    @showDetailsTab="notifyUser"
                  />
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item
              key='details'
              value='details'>
              <InvoiceDetailsTable
                ref="InvoiceDetailsTable"
                :tab="'values'"
                :filter="filterOperationalRoutines"
                @showInvoiceDetails="showInvoiceDetails"
              />
            </v-tab-item>
            <v-tab-item
              key='selected'
              value='selected'>
              <InvoiceDetails
                ref="InvoiceDetails"
                :card="card"
                @close="closeInvoiceDetails"
                @updateInvoiceValidationPage="updateInvoiceValidationPage"
                @updateSearch="updateInvoiceValidationPage"
              />
            </v-tab-item>
            <v-tab-item
              key='previews'
              value='previews'>
              <v-row
                class="mb-1">
                <v-col
                  cols=12>
                  <v-form
                    ref="SimplePreviewFilter">
                    <SimpleFilter
                      @search="handlerFilterPreview"
                      @generatePreview="generatePreview"
                      @updateCutOffDate="cutOffDates = $event"
                      @inSearch="inSearch = $event"
                      :loadingSearch="loadingSearchPreview"
                      :loadingPreviewGeneration="loadingPreviewGeneration"
                      :isPreview="true"
                      :inSearch="inSearch"
                    />
                  </v-form>
                </v-col>
                <v-col
                  cols="12">
                  <InvoicePreview
                    v-if="invoicePreviews.length > 0"
                  />
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
      <v-footer
        class="my-5 px-0 transparent">
        <v-row
          class="d-flex justify-end">
          <v-col
            v-bind="columnFooterProps"
            v-show="pageTab !== 'selected'">
            <v-btn
              x-large
              block
              outlined
              color="wine"
              @click="onClickBack()">
              Voltar
            </v-btn>
          </v-col>
        </v-row>
      </v-footer>
    </v-container>
    <SnackbarCustomize ref="SnackbarCustomize" />
    <FullScreenLoader
			v-if="loaded"
		/>
  </div>
</template>
<script>
import InvoiceDetailsTable from '@/components/OperationalRoutines/InvoiceDetailsTable.vue';
import InvoiceConference from '@/components/OperationalRoutines/InvoiceConference.vue';
import ConferenceFilter from '@/components/OperationalRoutines/ConferenceFilter.vue';
import ConferenceTable from '@/components/OperationalRoutines/ConferenceTable.vue';
import FullScreenLoader from '@/components/FullScreenLoader/FullScreenLoader.vue';
import InvoiceDetails from '@/components/OperationalRoutines/InvoiceDetails.vue';
import InvoicePreview from '@/components/OperationalRoutines/InvoicePreview.vue';
import SnackbarCustomize from '@/components/CustomAlerts/SnackbarCustomize.vue';
import SimpleFilter from '@/components/OperationalRoutines/SimpleFilter.vue'
import CustomFilter from '@/components/CustomFilter/CustomFilter.vue';

import OperationalRoutinesMixin from '@/shared/mixins/operationalRoutines/operationalRoutinesMixin';
import GenericFunctionsMixin from '@/shared/mixins/genericFunctions/genericFunctionsMixin';
import VerifyRoutesMixin from '@/shared/mixins/routeManagement/verifyRoutesMixin';
import InsuranceCarriersMixin from '@/shared/mixins/sdi/insuranceCarriersMixin';
import FinancialGroupsMixin from '@/shared/mixins/sdi/financialGroupsMixin';

import OperationalService from '@/services-http/operational/OperationalService';

import { cloneDeep } from 'lodash';
import moment from 'moment';
import { mapGetters, mapMutations } from 'vuex';

export default {
  components: {
    InvoiceDetailsTable,
    SnackbarCustomize,
    InvoiceConference,
    FullScreenLoader,
    ConferenceFilter,
    ConferenceTable,
    InvoiceDetails,
    InvoicePreview,
    CustomFilter,
    SimpleFilter,
  },

  data: () => ({
    moment: moment,
    pageTab: 'conference',
    pageTabClass: 'text-h6 font-weight-bold px-0 mr-8' ,
    operationalService: null,
    loadingSearch: false,
    panelExpand: false,
    filterOperationalRoutines: {},
    dataFilterConference: {},
    card: {},
    loaded: false,
    loadSearch: false,
    loadingSearchPreview: false,
    loadingPreviewGeneration: false,
    financialGroups: [],
    insuranceCarriers: [],
    contracts: [],
    subcontracts: [],
    dates: [],
    cutOffDates: [],
    inSearch: false,
    resetFilterConference: false,
    filtersForCheckDate: {},
    columnFooterProps: {
      cols: "12",
      xs: "12",
      sm: "12",
      md: "2",
      lg: "2",
    }
  }),

  mixins: [
    OperationalRoutinesMixin,
    InsuranceCarriersMixin,
    VerifyRoutesMixin,
    FinancialGroupsMixin,
    GenericFunctionsMixin
  ],

  methods: {
    ...mapMutations({
      updateContracts: 'operational-routines/updateContracts',
      resetModule: 'operational-routines/resetModule',
      resetPreview: 'operational-routines/resetPreview',
      setCardValidationId: 'operational-routines/setCardValidationId',
      cleanCarrierInvoiceValidationDetail: 'operational-routines/cleanCarrierInvoiceValidationDetail',
    }),
    clearFilters() {
      this.$refs.ConferenceFilter.clearFilterConference();
    },
    closeInvoiceDetails() {
      this.card = {};
      this.pageTab = 'details';
      this.setCardValidationId([]);
    },
    deleteEmptyParams(params) {
			Object.keys(params).forEach((key) => {
				if (["", null, undefined].some(item => item === params[key])) delete params[key]
			})
		},
    formatDate(date) {
      return date.format('YYYY-MM-DD');
    },
    async getInvoiceDetails(validationIds) {
      if (validationIds) {
        this.setCardValidationId([validationIds]);
        this.cleanCarrierInvoiceValidationDetail();
        if (Array.isArray(validationIds)) {
          for (const validationId of validationIds) {
            await this.operationalService.GetCarrierInvoiceValidationDetail(validationId);
          }
        } else {
          await this.operationalService.GetCarrierInvoiceValidationDetail(validationIds);
        }
      }
		},
    async createPreview() {
      this.deleteEmptyParams(this.filterOperationalRoutines);

      await this.operationalService.CreatePreview(this.filterOperationalRoutines)
      .then(async (res) => {
        this.$refs.SnackbarCustomize.open('success', 'Geração de prévia realizado!');
        await this.handlerFilterPreviewGenerated(this.filterOperationalRoutines);
      })
      .catch((err) => {
        this.$refs.SnackbarCustomize.open('error', 'Erro ao gerar prévia');
      })
      .finally(() => {
        this.loadingPreviewGeneration = false;
      });
    },
    async getCarrierInvoces() {
      this.deleteEmptyParams(this.filterOperationalRoutines);
      const params = new URLSearchParams(this.filterOperationalRoutines)
      const result = await Promise.all([
        this.operationalService.SearchCarrierInvoiceValidation(params),
        this.operationalService.SearchCarrierInvoicePriceValidation(params)
      ])

      if (!result[0]) this.$refs.SnackbarCustomize.open('error', 'Nenhum contrato foi encontrado');
      this.loadingSearch = false;
      this.updateContracts();
    },
    async searchPreviews() {
      this.filterOperationalRoutines.cutOffStartDate = null;
      this.filterOperationalRoutines.cutOffEndDate = null;
      this.filterOperationalRoutines.issueDate = null;
      this.deleteEmptyParams(this.filterOperationalRoutines);

      const params = new URLSearchParams(this.filterOperationalRoutines)
      const result = await Promise.all([
        this.operationalService.SearchPreviews(params),
      ])

      if (!result[0]) this.$refs.SnackbarCustomize.open('error', 'Nenhum contrato foi encontrado');
      this.inSearch = false;
      this.loadingSearchPreview = false;
      this.$refs.SimpleFilter.resetValidation();
    },
    async searchPreviewGenerated() {
      let paramsPreview = {...this.filterOperationalRoutines}

      paramsPreview.cutOffStartDate = null;
      paramsPreview.cutOffEndDate = null;
      paramsPreview.issueDate = null;
      paramsPreview.groupLast = true;

      this.deleteEmptyParams(paramsPreview);

      const params = new URLSearchParams(paramsPreview)
      const result = await Promise.all([
        this.operationalService.SearchPreviews(params),
      ])

      if (!result[0]) this.$refs.SnackbarCustomize.open('error', 'Nenhum contrato foi encontrado');
      this.inSearch = false;

      this.$refs.SimpleFilter.resetValidation();
    },
    async searchPreviews() {
      this.filterOperationalRoutines.cutOffStartDate = null;
      this.filterOperationalRoutines.cutOffEndDate = null;
      this.filterOperationalRoutines.issueDate = null;
      this.deleteEmptyParams(this.filterOperationalRoutines);

      const params = new URLSearchParams(this.filterOperationalRoutines)
      const result = await Promise.all([
        this.operationalService.SearchPreviews(params),
      ])

      if (!result[0]) this.$refs.SnackbarCustomize.open('error', 'Nenhum contrato foi encontrado');
      this.inSearch = false;
      this.loadingSearchPreview = false;
      this.$refs.SimpleFilter.resetValidation();
    },
    async searchPreviewGenerated() {
      let paramsPreview = {...this.filterOperationalRoutines}

      paramsPreview.cutOffStartDate = null;
      paramsPreview.cutOffEndDate = null;
      paramsPreview.issueDate = null;
      paramsPreview.groupLast = true;

      this.deleteEmptyParams(paramsPreview);

      const params = new URLSearchParams(paramsPreview)
      const result = await Promise.all([
        this.operationalService.SearchPreviews(params),
      ])

      if (!result[0]) this.$refs.SnackbarCustomize.open('error', 'Nenhum contrato foi encontrado');
      this.inSearch = false;

      this.$refs.SimpleFilter.resetValidation();
    },
    handleConference(commonFilterProperties) {
      this.resetFilterConference = false;

      this.filterConference = {
        ...this.dataFilterConference,
        startedDate: this.dates[0],
        endDate: this.dates[1],
      }

      this.$refs.ConferenceTable.handlerFilter(this.filterConference);
    },
    handlerClickFilter(dates, dateType) {
      if (dates && dates.length > 0) {
        this.processDates(dates, dateType);
      } else {
        this.dates = [];
      }

      const commonFilterProperties = this.setCommonFilterProperties();

      this.handleConference(commonFilterProperties)
    },
    async handlerFilter(value) {
      if (!this.$refs.SimpleFilter.validate()) return;
      this.loadingSearch = true;
			this.filterOperationalRoutines = cloneDeep(value);
      if (this.filterOperationalRoutines.competencyDate) {
        this.filterOperationalRoutines.competencyDate = moment(
          this.filterOperationalRoutines.competencyDate, 'MM/YYYY').format('YYYY-MM');
      }
			await this.getCarrierInvoces();
      this.inSearch = false;
		},
    async generatePreview(value) {
      if (!this.$refs.SimplePreviewFilter.validate()) return;
      this.loadingPreviewGeneration = true;
			this.filterOperationalRoutines = cloneDeep(value);
      if (this.filterOperationalRoutines.competencyDate) {
        this.filterOperationalRoutines.competencyDate = moment(
          this.filterOperationalRoutines.competencyDate, 'MM/YYYY').format('YYYY-MM');
      }
      if (this.cutOffDates.length > 0) {
        this.filterOperationalRoutines.cutOffStartDate = this.cutOffDates[0];
        this.filterOperationalRoutines.cutOffEndDate = this.cutOffDates[1];
      }
      delete this.filterOperationalRoutines.groupLast;

			await this.createPreview();
    },
    async handlerFilterPreview(value) {
      if (!this.$refs.SimplePreviewFilter.validate()) return;
      this.loadingSearchPreview = true;
      this.resetPreview();
			this.filterOperationalRoutines = cloneDeep(value);
      if (this.filterOperationalRoutines.competencyDate) {
        this.filterOperationalRoutines.competencyDate = moment(
          this.filterOperationalRoutines.competencyDate, 'MM/YYYY').format('YYYY-MM');
      }
			await this.searchPreviews();
    },
    async handlerFilterPreviewGenerated(value) {
      if (!this.$refs.SimplePreviewFilter.validate()) return;

      this.resetPreview();

			this.filterOperationalRoutines = cloneDeep(value);

			await this.searchPreviewGenerated();
    },
    hasFiltersConference(data, type) {
      this.dataFilterConference = cloneDeep(data);
      this.filtersForCheckDate = cloneDeep(data);
      this.dataFilterConference = this.setMonthYearReference(this.dataFilterConference);
    },
    notifyUser() {
      if (this.carrierInvoicePriceValidation.length === 0) {
        this.$refs.SnackbarCustomize.open('error', 'Realize uma pesquisa na aba Validações para ver os detalhes das faturas');
      }
      this.pageTab = 'details'
    },
    openExpandedContent(expand) {
      this.panelExpand = expand;
    },
    onClickBack() {
      this.redirectRouter('HomeAllMenus', { isRHProtegido: this.isRHProtegido });
    },
    processDates(dates, dateType) {
      const startDate = moment(dates[0]);
      const endDate = moment(dates[1]);
      this.dateType = dateType;

      this.dates[0] = this.setDateTimezone(startDate.startOf('day'))
      this.dates[1] = this.setDateTimezone(endDate.endOf('day').add(3, 'hours'))

      this.dates[0] = this.formatDate(this.dates[0])
      this.dates[1] = this.formatDate(this.dates[1])
    },
    resetStatusFilters(data) {
      this.resetFilterConference = data;
    },
    setCommonFilterProperties() {
      return {
        startDate: this.dates[0],
        endDate: this.dates[1],
        dateType: this.dateType,
      };
    },
    setDateTimezone(date) {
      return date.add(3, 'hours')
    },
    setMonthYearReference(data) {
      if (!Boolean(data.competence)) return data
      data.monthReference = moment(data.competence, 'MM/YYYY').format('MM')
      data.yearReference = moment(data.competence, 'MM/YYYY').format('YYYY')
      delete data.competence;
      return data
    },
    async selectCard(card) {
      this.pageTab = 'details';
      await this.showInvoiceDetails(card.selectedContracts.map(el => el.validationId), card);
    },
    async showInvoiceDetails(validationIds, card) {
      this.loaded = true;
			await this.getInvoiceDetails(validationIds);
      this.pageTab = 'selected';
      this.card = card;
      this.loaded = false;
		},
    async updateInvoiceValidationPage() {
      this.loaded = true;
      if (this.cardValidationIds) {
        await this.getInvoiceDetails(this.cardValidationIds);
      }
      this.getCarrierInvoces();
      this.loaded = false;
    },
  },

  computed: {
    ...mapGetters({
      invoicePriceValidations: 'operational-routines/invoicePriceValidations',
      routinesInvoiceValidations: 'operational-routines/invoiceValidations',
      cardValidationIds: 'operational-routines/cardValidationIds',
      invoicePreviews: 'operational-routines/invoicePreviews',
    }),
    carrierInvoicePriceValidation() {
			return this.invoicePriceValidations;
		},
    invoiceValidations() {
			return this.routinesInvoiceValidations;
		},
  },

  created() {
    this.operationalService = new OperationalService();
  },

  async mounted() {
    this.operationalService.GetCarrierInvoiceTypes();
    this.getFinancialGroups();
    this.getInsuranceCarriers();
    await this.operationalService.GetFileTypes();
  },

  destroyed() {
    this.resetModule();
  }
}
</script>

<style>
#OperationalRoutinesTabs div .v-tabs-slider-wrapper {
  height: 3px !important;
}
</style>
