<template>
  <v-alert
    outlined
    color="transparent"
    class="pa-0 white">
    <v-row>
      <v-col
        md="5"
        sm="12">
        <v-text-field
          v-model="filterText"
          label="Filtrar Opções disponíveis"
          append-icon="mdi-magnify"
          class="mx-1"
          style="max-height: 50px; margin: 0;"
        />
        <v-list
          dense
          height="200"
          style="overflow-y: auto; margin: 0;"
          outlined
          class="rounded-lg">
          <v-list-item-group
            multiple
            v-model="availableIndexOptions"
            class="fill-height">
            <v-list-item
              v-for="(option, i) in filteredOptions"
              :key="i">
              <template
                v-slot:default="{ active }">
                <v-list-item-content
                  @click="clearRemove()">
                  <v-list-item-title v-text="option.name" />
                </v-list-item-content>

                <v-list-item-action>
                  <v-icon
                    small
                    v-if="active"
                    color="primary lighten-1">
                    fas fa-angle-double-right
                  </v-icon>
                </v-list-item-action>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>

      <v-col
        md="2"
        sm="12"
        class="d-flex flex-column align-center justify-center">
        <v-btn block max-height="40" :disabled="addButtonDisabled" @click="addSelecteds()" class="my-1">
          Adicionar
          <v-icon right dark>fas fa-angle-right</v-icon>
        </v-btn>

        <v-btn block max-height="40" :disabled="removeButtonDisabled" @click="removeSelecteds()" class="my-1">
          <v-icon left dark>fas fa-angle-left</v-icon>
          Remover
        </v-btn>

        <v-btn
          block
          max-height="40"
          :disabled="availableOptions.length === 0"
          @click="addAll()"
          class="my-1">
          Adicionar tudo
          <v-icon
            right
            dark>
            fas fa-angle-double-right
          </v-icon>
        </v-btn>

        <v-btn
          block
          max-height="40"
          :disabled="localSelectedOptions.length === 0"
          @click="removeAll()"
          class="my-1">
          <v-icon
            left
            dark>
            fas fa-angle-double-left
          </v-icon>
          Remover tudo
        </v-btn>
      </v-col>

      <v-col
        md="5"
        sm="12">
        <v-text-field
          v-model="filterSelectedText"
          label="Filtrar Opções Selecionadas"
          append-icon="mdi-magnify"
          class="mx-1"
          style="max-height: 50px; margin: 0;"
        />
        <v-list
          dense
          height="200"
          style="overflow-y: auto; margin: 0;"
          outlined
          class="rounded-lg">
          <v-list-item-group
            multiple
            class="fill-height"
            v-model="availableIndexOptionsToRemove">
            <draggable
              v-model="localSelectedOptions"
              group="options"
              @end="onReorder"
              handle=".dual-list-drag-handle"
              item-key="id">
              <v-list-item
               v-for="(option, i) in filteredSelectedOptions"
                :key="option.id"
                :value="option.id">
                <template v-slot:default="{ active }">
                  <v-list-item-action
                    class="d-flex flex-row">
                    <v-icon
                      class="dual-list-drag-handle mr-3"
                      small>
                      fas fa-bars
                    </v-icon>
                    <v-icon
                      small
                      v-if="active"
                      color="primary lighten-1">
                      fas fa-angle-double-left
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content
                    @click="clearAdd()"
                    align="right">
                    <v-list-item-title v-text="option.name" />
                  </v-list-item-content>
                </template>
              </v-list-item>
            </draggable>
          </v-list-item-group>
        </v-list>
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  name: "DualListBox",

  components: {
    draggable
  },

  data: () => ({
    addButtonDisabled: true,
    availableIndexOptions: [],
    availableIndexOptionsToRemove: [],
    removeButtonDisabled: true,
    localSelectedOptions: [],
    filterText: '',
    filterSelectedText: '',
  }),

  props: {
    availableOptions: {
      type: Array,
      default: () => [],
    },

    selectedOptions: {
      type: Array,
      default: () => [],
    },

    verifyFields: {
      type: Boolean,
      default: false,
    },

    defaultColor: {
      type: String,
      default: "primary"
    },
    verifyFields: {
      type: Boolean,
      default: false
    },
  },

  computed: {
    filteredOptions() {
      return this.availableOptions.filter(option =>
        option.name.toLowerCase().includes(this.filterText.toLowerCase())
      );
    },
    filteredSelectedOptions() {
      return this.localSelectedOptions.filter(option =>
        option.name.toLowerCase().includes(this.filterSelectedText.toLowerCase())
      );
    }
  },
  watch: {
    availableIndexOptions: {
      handler(array) {
        this.addButtonDisabled = !array.length;
        this.removeButtonDisabled = true;
      },
      deep: true,
      immediate: true
    },

    selectedOptions: {
      handler(val) {
        this.localSelectedOptions = [...val];
      },
      deep: true,
      immediate: true
    },

    availableIndexOptionsToRemove: {
      handler(array) {
        this.removeButtonDisabled = !array.length;
        this.addButtonDisabled = true;
      },
      deep: true,
      immediate: true
    },

    verifyFields: {
      handler(val) {
        if (val) {
          this.addButtonDisabled = true;
          this.removeButtonDisabled = true;
          this.availableIndexOptions = [];
          this.availableIndexOptionsToRemove = [];
        }
      },
      deep: true,
      immediate: true
    },

    localSelectedOptions: {
      handler(newValue, oldValue) {
        const newValueIds = Array.isArray(newValue) && newValue.length > 0 ? newValue.map(el => el.id) : [];
        const oldValueIds = Array.isArray(oldValue) && oldValue.length > 0 ? oldValue.map(el => el.id) : [];

        if (newValueIds.length === 0) return;

        const differentLength = newValue.length !== oldValue.length;
        const fieldsValidation = !newValueIds.every(
          (value, index) => value === oldValueIds[index]);

        if (differentLength || fieldsValidation) {
          this.availableIndexOptionsToRemove = [];

          this.$emit('selectedOptions', newValue);
        }
      },
      deep: true,
      immediate: true
    }
  },

  methods: {
    addAll() {
      let selectedFields = [ ...this.localSelectedOptions ];
      let avaliableFields = [ ...this.availableOptions ];

      selectedFields = [ ...selectedFields, ...avaliableFields ];
      avaliableFields = [];

      this.emitOptions(selectedFields, avaliableFields);
      this.addButtonDisabled = true;
    },

    addSelecteds() {
      let selectedFields = [ ...this.localSelectedOptions ];
      let avaliableFields = [ ...this.availableOptions ];

      if (this.availableIndexOptions.length) {
        const avaliableItems = this.availableIndexOptions.map(i => this.filteredOptions[i]);

        avaliableItems.forEach((el) => {
          selectedFields.push(el);

          const indexToRemove = avaliableFields.findIndex(item => item.id === el.id);
          if (indexToRemove !== -1) {
            avaliableFields.splice(indexToRemove, 1);
          }
        });

        avaliableFields = this.sortAvailableFields(avaliableFields);

        this.availableIndexOptions = [];

        this.verifyIfEnabled();
      }

      this.emitOptions(selectedFields, avaliableFields);
    },

    clearAdd() {
      this.availableIndexOptions = [];
    },

    clearRemove() {
      this.availableIndexOptionsToRemove = [];
    },

    emitOptions(selectedFields, avaliableFields) {
      this.$emit('selectedOptions', selectedFields);
      this.$emit('availableOptions', avaliableFields);
      this.$emit('updateParttern');
    },

    removeAll() {
      let selectedFields = [ ...this.localSelectedOptions ];
      let avaliableFields = [ ...this.availableOptions ];

      avaliableFields = [ ...avaliableFields, ...selectedFields ];
      selectedFields = [];

      avaliableFields = this.sortAvailableFields(avaliableFields);

      this.emitOptions(selectedFields, avaliableFields);
      this.removeButtonDisabled = true;
    },

    removeSelecteds() {
      let selectedFields = [ ...this.localSelectedOptions ];
      let avaliableFields = [ ...this.availableOptions ];

      if (this.availableIndexOptionsToRemove.length) {
        const selectedIndexToRemove = this.availableIndexOptionsToRemove
          .map((id) => selectedFields.findIndex(el => el.id === id));

        const removeItems = selectedIndexToRemove.map(i => this.filteredSelectedOptions[i]);

        removeItems.forEach((el) => {
          if (el) {
            avaliableFields.push(el);

            const indexToRemove = selectedFields.findIndex(item => item.id === el.id);
            if (indexToRemove !== -1) {
              selectedFields.splice(indexToRemove, 1);
            }
          }
        });

        avaliableFields = this.sortAvailableFields(avaliableFields);
        this.availableIndexOptionsToRemove = [];

        this.verifyIfEnabled();
      }

      this.emitOptions(selectedFields, avaliableFields);
    },

    sortAvailableFields(avaliableFields) {
      return avaliableFields.sort((a, b) => (a.name > b.name ? 1 : -1));
    },

    verifyIfEnabled() {
      this.addButtonDisabled = !this.availableOptions.length || this.availableIndexOptions.length === 0;
      this.removeButtonDisabled = !this.localSelectedOptions.length || this.availableIndexOptionsToRemove.length === 0;
    },
    onReorder() {
      let selectedFields = [ ...this.localSelectedOptions ];
      let avaliableFields = [ ...this.availableOptions ];

      this.emitOptions(selectedFields, avaliableFields);
    }
  }
}
</script>

<style>
.dual-list-drag-handle {
  cursor: move;
}
</style>